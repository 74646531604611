import css from "./MainMenu.module.scss";
import Section from "@/components/atoms/Section/Section";
import { largeColumnWidth } from "@/lib/globals";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { LeftDesktopMenu } from "@/components/organisms/MainMenu/LeftDesktopMenu";
import LeftMobileMenu from "@/components/organisms/MainMenu/LeftMobileMenu";
import { UserMenu } from "@/components/organisms/MainMenu/UserMenu";
import { useTranslation } from "react-i18next";
import { useDebounceValue } from "usehooks-ts";
import { useParams } from "react-router-dom";
import {
    useGetEducationalPathForApp,
    useGetEducationalProgramsForApp,
    useGetModuleForApp,
} from "@/services";

import { useGetDidacticToolForAppQuery } from "@bespeak/apollo";
import useScrollDirection from "@/services/useGetScrollDirection/useGetScrollDirection";
import { useMediaQuery } from "usehooks-ts";

export const MainMenu = () => {
    const { t } = useTranslation("common", { keyPrefix: "main-menu" });
    const mediaQueryDesktop = useMediaQuery("(min-width: 1024px)");
    const [scrollPosition, setScrollPosition] = useState(window.scrollY);

    const [debouncedScrollPosition] = useDebounceValue(scrollPosition, 25);
    const scrollDirection = useScrollDirection();

    const { programId, learningPathId, moduleId, didacticToolId } = useParams<{
        programId: string;
        learningPathId: string;
        moduleId: string;
        didacticToolId: string;
    }>();

    const { educationalPath } = useGetEducationalPathForApp(
        learningPathId || "",
    );
    const { module } = useGetModuleForApp(moduleId || "");

    const { data: { getDidacticToolForApp: didacticTool } = {} } =
        useGetDidacticToolForAppQuery({
            variables: { id: didacticToolId || "" },
            skip: !didacticToolId,
        });

    const { educationalPrograms, loading } = useGetEducationalProgramsForApp();

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        setScrollPosition(scrollPosition);
    };

    useEffect(() => {
        handleScroll();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [debouncedScrollPosition]);

    const menuLinks = [
        {
            label: t("home"),
            href: programId ? `/program/${programId}` : "/",
        },
    ];

    return (
        <div
            className={clsx({
                [css.Fixed]: debouncedScrollPosition > 0 && !didacticTool,
                [css.ScrollingUp]: scrollDirection === "up" && didacticTool,
                [css.ScrollingDown]: scrollDirection === "down" && didacticTool,
                [css.ScrollingTop]: scrollDirection === "top" && didacticTool,
            })}
        >
            {/*
            Add a curved box shadow to the main menu when the user scrolls down,
            by adding two ellipsis elements as drop shadow behind the bar.
            */}
            <div className={css.Shades}>
                <div></div>
                <div></div>
            </div>
            <div
                className={clsx(css.MainMenu, {
                    [css.MainMenuDidacticTool]: didacticTool,
                    [css.ScrollingUp]: scrollDirection === "up" && didacticTool,
                    [css.ScrollingDown]:
                        scrollDirection === "down" && didacticTool,
                    [css.ScrollingTop]:
                        scrollDirection === "top" && didacticTool,
                    [css.Fixed]: debouncedScrollPosition > 0 && !didacticTool,
                    [css.MainMenuMobile]: !mediaQueryDesktop,
                })}
            >
                <Section
                    spacing={"none"}
                    maxWidth={largeColumnWidth}
                    className={css.Content}
                >
                    {!loading ? (
                        <>
                            {mediaQueryDesktop ? (
                                <LeftDesktopMenu
                                    program={educationalPrograms?.at(0)}
                                    learningPath={educationalPath}
                                    module={module}
                                    didacticTool={didacticTool}
                                    links={menuLinks}
                                />
                            ) : (
                                <LeftMobileMenu
                                    links={menuLinks}
                                    program={educationalPrograms?.at(0)}
                                    learningPath={educationalPath}
                                    module={module}
                                    didacticTool={didacticTool}
                                />
                            )}
                        </>
                    ) : null}
                    {!didacticTool ? <UserMenu /> : null}
                </Section>
            </div>
        </div>
    );
};

export default MainMenu;
