import { useContext, type ReactNode } from "react";
import { Outlet } from "react-router-dom";
import MainMenu from "@/components/organisms/MainMenu";
import { HelpBubble } from "@bespeak/ui";
import { useParams } from "react-router-dom";
import { AlgoliaSearchContext } from "@/provider/AlgoliaSearch";
import { SearchField } from "@/components/molecules/SearchField";
import type { AlgoliaSearchContextType } from "@/provider/AlgoliaSearch/AlgoliaSearch.types";

export default function MainLayout({ children }: { children?: ReactNode }) {
    const didacticToolId = useParams<{ didacticToolId?: string }>()
        .didacticToolId;

    const { tenantHasSearchKey } =
        useContext<AlgoliaSearchContextType>(AlgoliaSearchContext);

    return (
        <>
            {children || <Outlet />}
            <MainMenu />
            {tenantHasSearchKey ? <SearchField /> : null}
            {didacticToolId === undefined ? <HelpBubble /> : null}
        </>
    );
}
