import { Fragment, useMemo } from "react";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import type { ReferenceGridProps } from "./ReferenceCardGrid.types";
import ReferenceCardGridItem from "./ReferenceCardGridItem";
import Skeleton from "@mui/material/Skeleton";
import css from "./ReferenceCardGridItem.module.scss";
import clsx from "clsx";

export interface ReferenceGridProps {
    data: any;
    buttons: any[];
    loading: boolean;
    isBlock: boolean;
    onAddItem: (item: any) => void;
    addedCards: any[];
}

export const ReferenceCardGrid = ({
    data,
    buttons,
    loading,
    isBlock = false,
    onAddItem,
    addedCards,
}: ReferenceGridProps) => {
    const cards = data?.rows;
    const skeleton = useMemo(
        () =>
            [...Array(12).keys()].map((i) => (
                <Grid item key={i} xs={3}>
                    <Skeleton variant="rounded" height={430} />
                </Grid>
            )),
        [],
    );

    return (
        <Paper style={{ padding: "32px 20px" }} elevation={1}>
            {!isBlock && (
                <Toolbar data-testid="toolbar">
                    <Grid container alignItems="center">
                        <Grid item sx={{ ml: "auto" }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                }}
                            >
                                {buttons?.map((group, groupIndex) => (
                                    <Fragment key={`${groupIndex}`}>
                                        {groupIndex > 0 && (
                                            <Divider
                                                sx={{ mx: 2 }}
                                                orientation="vertical"
                                                variant="middle"
                                                flexItem
                                            />
                                        )}
                                        {group.map((button, buttonIndex) => (
                                            <Button
                                                key={`${groupIndex}_${buttonIndex}`}
                                                {...button}
                                            />
                                        ))}
                                    </Fragment>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                </Toolbar>
            )}

            <Box>
                <div
                    className={clsx(
                        "ReferenceCardGridLayout",
                        css.ReferenceCardGridLayout,
                    )}
                >
                    {loading && <>{skeleton}</>}

                    {!loading &&
                        cards?.map((card) => (
                            <Grid item key={card.id} xs={3}>
                                <ReferenceCardGridItem
                                    isAdded={addedCards?.find(
                                        (addedCard: any) =>
                                            addedCard.id === card.id,
                                    )}
                                    isBlock={isBlock}
                                    item={card}
                                    onAddItem={onAddItem}
                                />
                            </Grid>
                        ))}
                </div>
            </Box>
        </Paper>
    );
};

export default ReferenceCardGrid;
