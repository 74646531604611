import parse from "html-react-parser";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import type { ReactNode } from "react";
import clsx from "clsx";
import css from "./ConvertedHTML.module.css";

interface ConvertedHTMLProps {
    /**
     * The JSON value to convert to HTML.
     *
     * It's fine if this is just plain text, it will just render as plain text.
     */
    value?: string | null | undefined;

    /**
     * Optionally provide a custom wrapper element. If omitted, a default <div>
     * is used
     * @param props
     */
    wrapper?: (props: WrapperProps) => JSX.Element;
}

/**
 * Converts a JSON value to HTML.
 *
 * If the value is not a valid JSON, it will be rendered as plain text. This
 * makes it safe to use with user input in any form, but also for StoryBook.
 */
const ConvertedHTML = ({ value, wrapper }: ConvertedHTMLProps) => {
    if (typeof value !== "string") return null;
    if (!value) return null;

    const WrapperElement = wrapper ?? Wrapper;

    try {
        const parsedJSON = value ? JSON.parse(value || "") : "";
        const converter = new QuillDeltaToHtmlConverter(parsedJSON.ops);
        const ConvertedHTML = parse(converter.convert());

        return <WrapperElement>{ConvertedHTML}</WrapperElement>;
    } catch (error) {
        console.warn(
            "ConvertedHTML: failed to parse JSON, falling back to plain text.",
        );

        return <WrapperElement>{value}</WrapperElement>;
    }
};

interface WrapperProps {
    children: ReactNode;
}

function Wrapper(props: WrapperProps) {
    return <div className={clsx(css.ConvertedHTML)}>{props.children}</div>;
}

export default ConvertedHTML;
