import type { SceneBuildingBlock } from "@/components/organisms/BuildingBlockMapper";
import Block from "../../../atoms/Block";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import { AssetUpload } from "src/components/organisms/AssetUpload";
import Input from "@/components/atoms/Input";
import type { AssetDto } from "@bespeak/apollo";

interface SceneBuildingBlockEditorComponentProps extends SceneBuildingBlock {
    elevate?: boolean;
    onChange?: (buildingBlock: SceneBuildingBlock) => void;
    asset?: AssetDto | undefined;
    errors?: any;
}

export function SceneBuildingBlockEditorComponent(
    props: SceneBuildingBlockEditorComponentProps,
) {
    const { t } = useTranslation("common");

    type Fields = Pick<SceneBuildingBlock, "title" | "description">;

    const handleChange =
        (key: keyof Fields) => (value: Fields[keyof Fields]) => {
            props.onChange?.({
                ...props,
                [key]: value,
            });
        };

    const registerControl = (key: keyof Fields) => ({
        value: props[key],
        onChange: handleChange(key),
    });

    const titleError = resolveError({
        errors: props.errors,
        path: "title",
    });

    const descriptionError = resolveError({
        errors: props.errors,
        path: "description",
    });

    const assetIdError = resolveError({
        errors: props.errors,
        path: "assetId",
    });

    return (
        <Block elevate={props.elevate}>
            <Block.Main>
                <Stack gap={1} direction="column">
                    <Input
                        {...registerControl("title")}
                        label={t("scene", "Titel")}
                        placeholder={t("title-placeholder", "Titel")}
                        variant="h5"
                        error={titleError}
                    />
                    <Input
                        {...registerControl("description")}
                        label={t("scene", "Omschrijving")}
                        placeholder={t(
                            "description-placeholder",
                            "Omschrijving",
                        )}
                        variant="body1"
                        error={descriptionError}
                    />
                    <AssetUpload
                        availableModes={["external_video"]}
                        asset={props?.asset?.id || props.asset || ""}
                        onChange={(assetId) => {
                            props.onChange?.({
                                ...props,

                                ...(assetId != null && {
                                    asset: assetId,
                                }),

                                ...(assetId === null && {
                                    asset: undefined,
                                }),
                            });
                        }}
                        error={assetIdError}
                        label={t("external-video-url", "Video URL")}
                    />
                </Stack>
            </Block.Main>
        </Block>
    );
}

export default SceneBuildingBlockEditorComponent;
