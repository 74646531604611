import clsx from "clsx";
import css from "./Divider.module.css";

export const DividerSize = {
    Sm: "Sm",
    Md: "Md",
    Lg: "Lg",
} as const;

export type DividerSize = keyof typeof DividerSize;

export const DividerDirection = {
    Horizontal: "Horizontal",
    Vertical: "Vertical",
} as const;

export type DividerDirection = keyof typeof DividerDirection;

export const DividerColor = {
    Default: "Default",
    Inverse: "Inverse",
    Gray: "Gray",
} as const;

export type DividerColor = keyof typeof DividerColor;

export const DividerSpacing = {
    None: "None",
    Default: "Default",
} as const;

export type DividerSpacing = keyof typeof DividerSpacing;

export type DividerProps = {
    size?: DividerSize;
    direction?: DividerDirection;
    color?: DividerColor;
    spacing?: DividerSpacing;
};

export function DividerRoot(props: DividerProps) {
    const {
        size,
        direction = DividerDirection.Horizontal,
        color = DividerColor.Default,
        spacing = DividerSpacing.Default,
    } = props;

    return (
        <div
            className={clsx(css.Divider, {
                [css.sizeSm]: size === DividerSize.Sm,
                [css.sizeMd]: size === DividerSize.Md,
                [css.sizeLg]: size === DividerSize.Lg,
                [css.colorDefault]: color === DividerColor.Default,
                [css.colorInverse]: color === DividerColor.Inverse,
                [css.colorGray]: color === DividerColor.Gray,
                [css.directionVertical]:
                    direction === DividerDirection.Vertical,
                [css.directionHorizontal]:
                    direction === DividerDirection.Horizontal,
                [css.spacingNone]: spacing === DividerSpacing.None,
                [css.spacingDefault]: spacing === DividerSpacing.Default,
            })}
        />
    );
}

export const Divider = Object.assign(DividerRoot, {
    Size: DividerSize,
    Direction: DividerDirection,
    Color: DividerColor,
    Spacing: DividerSpacing,
});
