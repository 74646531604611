import ConvertedHTML from "@/ConvertedHTML";

export type DialogContentProps = {
    content?: string | null | undefined;
};

export const DialogContent = ({ content }: DialogContentProps) => {
    return (
        <div>
            <ConvertedHTML value={content} />
        </div>
    );
};

export default DialogContent;
