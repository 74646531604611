import Stack from "@mui/material/Stack";
import Block from "../../../atoms/Block";

import {
    type BuildingBlocksLabel,
    type InteractiveQuestionBuildingBlock,
} from "@/components/organisms/BuildingBlockMapper";
import SceneBuildingBlockComponent from "@/components/organisms/BuildingBlockMapper/blocks/SceneBuildingBlock.component";
import InteractiveQuestionEditor from "../../InteractiveQuestionEditor";
import resolveError from "@/components/atoms/ValidationErrorLabel/ResolveError";
import type { ValidationError } from "yup";

export interface InteractiveQuestionBuildingBlockComponentProps
    extends InteractiveQuestionBuildingBlock {
    availableLabels?: BuildingBlocksLabel[];
    onChange?: (buildingBlock: InteractiveQuestionBuildingBlock) => void;
    errors?: ValidationError[];
}

export function InteractiveQuestionBuildingBlockComponent(
    props: InteractiveQuestionBuildingBlockComponentProps,
) {
    const questionError = resolveError({
        errors: props.errors,
        resolver: (error) =>
            error?.path?.startsWith(
                `interactiveQuestion.${props.id}.question`,
            ) ?? false,
    });
    const sceneError = resolveError({
        errors: props.errors,
        resolver: (error) =>
            error.path?.startsWith(
                `interactiveQuestion.${props.id}.sceneWhenWrong`,
            ) ?? false,
    });

    return (
        <Block>
            <Block.Main>
                <Stack gap={4} direction="column">
                    <SceneBuildingBlockComponent
                        {...props.sceneWhenWrong}
                        errors={sceneError}
                        onChange={(sceneWhenWrong) => {
                            props.onChange?.({
                                ...props,
                                sceneWhenWrong,
                            });
                        }}
                    />
                    <Stack gap={4}>
                        <InteractiveQuestionEditor
                            question={props.question}
                            onChange={(questionBlock) => {
                                props.onChange?.({
                                    ...props,
                                    question: questionBlock as any,
                                });
                            }}
                            errors={questionError ? [questionError] : undefined}
                        />
                    </Stack>
                </Stack>
            </Block.Main>
        </Block>
    );
}

export default InteractiveQuestionBuildingBlockComponent;
