import css from "./DialogFooter.module.css";
import Button from "@/Button";
import { useTranslation } from "react-i18next";

export type DialogFooterProps = {
    onClick?: () => void;
    closeDialog?: () => void;
    buttonLabel?: string;
};

export const DialogFooter = ({
    onClick,
    buttonLabel,
    closeDialog,
}: DialogFooterProps) => {
    const { t } = useTranslation("common", {
        keyPrefix: "dialog",
    });

    return (
        <div className={css.DialogFooter}>
            <Button variant="text" size="tiny" onClick={closeDialog}>
                {t("close", "Sluiten")}
            </Button>
            {buttonLabel && !!onClick ? (
                <Button size="tiny" onClick={onClick}>
                    {buttonLabel}
                </Button>
            ) : null}
        </div>
    );
};

export default DialogFooter;
