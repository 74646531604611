import { Select, SelectItem } from "@bespeak/ui";
import type { GroupDto } from "@bespeak/apollo";
import type { Key } from "@react-types/shared";
import css from "./GroupsDropdown.module.css";
import { useTranslation } from "react-i18next";

const INDENT_CHAR = "↳";
const NON_BREAKING_SPACE = "\u00A0";

type DropdownProps = {
    groups: GroupDto[] | undefined;
    defaultSelectedKey?: Key;
    onChange?: (groupId: Key) => void;
    onBlur?: () => void;
};

type Group = {
    id: string;
    name: string;
    subgroups?: Group[];
    indent?: number;
};

function flattenGroups({
    id,
    name,
    subgroups = [],
    indent = 0,
}: Group): Group[] {
    return [
        { id, name, indent },
        ...subgroups.flatMap((subgroup) =>
            flattenGroups({ ...subgroup, indent: indent + 1 }),
        ),
    ];
}

export function Dropdown(props: DropdownProps) {
    const { t } = useTranslation();

    if (!props.groups) return;

    const options = props.groups.flatMap(flattenGroups);

    return (
        <Select
            items={options}
            onSelectionChange={props.onChange}
            defaultSelectedKey={props.defaultSelectedKey}
            onBlur={props.onBlur}
            className={css.select}
            style={{ width: "100%" }}
            placeholder={t("select-group", "Selecteer Groep")}
            label={t("select-group", "Selecteer Groep")}
        >
            {(item) => (
                <SelectItem id={item.id}>
                    <Indent indent={item.indent} />
                    <span className={css.SelectItem}>{item.name}</span>
                </SelectItem>
            )}
        </Select>
    );
}

function Indent({ indent = 0 }: { indent?: number }) {
    return (
        <i className={css.SelectIndent}>
            {NON_BREAKING_SPACE.repeat((indent ?? 0) * 2)}
            {indent ? `${INDENT_CHAR} ` : ""}
        </i>
    );
}
