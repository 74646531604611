import {
    type GroupDto,
    useGetGroupsForAuthenticatedUserQuery,
} from "@bespeak/apollo";
import { useMemo } from "react";

export function useGetGroupsForAuthenticatedUser() {
    const { data, loading } = useGetGroupsForAuthenticatedUserQuery();
    const { getGroupsForAuthenticatedUser } = data || {};

    const sortedGroups = useMemo(() => {
        if (!getGroupsForAuthenticatedUser) return {};

        const sortGroup = (group: GroupDto): GroupDto => ({
            ...group,
            subgroups:
                group.subgroups.length > 0
                    ? group.subgroups
                          .toSorted((a, b) => a.name.localeCompare(b.name))
                          .map(sortGroup)
                    : [],
        });

        return getGroupsForAuthenticatedUser
            .toSorted((a, b) => a.name.localeCompare(b.name))
            .map(sortGroup);
    }, [getGroupsForAuthenticatedUser]);

    return {
        groups: sortedGroups,
        loading,
    };
}

export default useGetGroupsForAuthenticatedUser;
