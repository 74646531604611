import type { HTMLAttributes, ReactNode } from "react";
import clsx from "clsx";
import css from "./DialogBase.module.css";
interface DialogBaseProps
    extends Pick<HTMLAttributes<HTMLDivElement>, "className" | "style"> {
    children: ReactNode;
    active?: boolean | null | undefined;
    onClickOutside?: () => void;
}

export default function DialogBase(props: DialogBaseProps) {
    return (
        <div
            data-testid="dialog"
            className={clsx(css.DialogBase, props.className, {
                [css.DialogBaseActive]: props.active,
            })}
            data-active={props.active ? true : false}
            style={props.style}
        >
            {props.children}
            <div
                onClick={props.onClickOutside}
                className={css.DialogBackdrop}
            ></div>
        </div>
    );
}
