import { create } from "zustand";
import type { Key } from "@react-types/shared";
import { useLocalStorage } from "usehooks-ts";

type PreferencesStore = {
    groupId: Key | undefined;
    groupLabel: string | null;
};

type PreferencesStoreActions = {
    setCurrentGroupId: (groupId: Key) => void;
    setCurrentGroupLabel: (groupLabel: string) => void;
};

export const usePreferencesStore = create<
    PreferencesStore & PreferencesStoreActions
>()((set) => ({
    groupId: undefined,
    groupLabel: null,
    setCurrentGroupId: (groupId: Key) => {
        set({ groupId });
    },
    setCurrentGroupLabel: (groupLabel: string) => {
        set({ groupLabel });
    },
}));

export const currentGroupIdSelector = (state: PreferencesStore) =>
    state.groupId;

export const setCurrentGroupIdSelector = (state: PreferencesStoreActions) =>
    state.setCurrentGroupId;

export const useCurrentGroupId = () =>
    usePreferencesStore(currentGroupIdSelector);

export const useSetCurrentGroupId = () =>
    usePreferencesStore(setCurrentGroupIdSelector);

export const useGroupStore = () => {
    const [value, setValue, removeValue] = useLocalStorage<Key>(
        "witty/current-group",
        "",
    );

    return {
        value,
        setValue,
        removeValue,
    };
};
