import type { Key } from "@react-types/shared";
import { Dropdown } from "./Dropdown";
import { useNavigate } from "react-router-dom";
import { useGetEducationalProgramsForApp } from "@/services";
import { useGetAuthenticatedUserQuery } from "@bespeak/apollo";

const ProgramDropdown = () => {
    const { educationalPrograms } = useGetEducationalProgramsForApp();
    const { data } = useGetAuthenticatedUserQuery();

    const user = data?.getAuthenticatedUser;
    const navigate = useNavigate();

    const handleSelectionChange = (programId: Key) => {
        if (!user) return;

        localStorage.setItem(`witty/${user.id}/program`, programId.toString());
        navigate(`/program/${programId}`);
    };

    return (
        <Dropdown
            programs={educationalPrograms}
            onChange={handleSelectionChange}
        />
    );
};

export default ProgramDropdown;
