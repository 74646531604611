import type { CSSProperties, ReactNode } from "react";
import clsx from "clsx"

import type {
    ListBoxItemProps,
    SelectProps as SelectTypes,
    ValidationResult,
} from "react-aria-components";

import {
    FieldError,
    Text,
    Button,
    Label,
    ListBox,
    ListBoxItem,
    Popover,
    Select as SelectComponent,
    SelectValue,
} from "react-aria-components";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import css from "./SelectItem.module.css";

interface SelectProps<T extends object>
    extends Omit<SelectTypes<T>, "children"> {
    label?: string;
    placeholder?: string;
    description?: string;
    errorMessage?: string | ((validation: ValidationResult) => string);
    items?: Iterable<T>;
    children: ReactNode | ((item: T) => ReactNode);
    fullWidth?: boolean;
}

export function Select<T extends object>({
    label,
    placeholder,
    description,
    errorMessage,
    children,
    items,
    ...props
}: SelectProps<T>) {
    return (
        <SelectComponent
            {...props}
            placeholder={placeholder}
            style={{
                ...(props.fullWidth && { width: "100%" }),
            }}
        >
            <Label>{label}</Label>
            <Button
                style={{
                    maxWidth: "initial",
                    display: "flex",
                    justifyContent: "space-between",
                    ...(props.fullWidth && { width: "100%" }),
                }}
            >
                <SelectValue />
                <ExpandMoreIcon
                    className={clsx(`selectItem`, css.SelectItemIcon)}
                />
            </Button>
            {description && <Text slot="description">{description}</Text>}
            <FieldError>{errorMessage}</FieldError>
            <Popover offset={-20}>
                <ListBox items={items}>{children}</ListBox>
            </Popover>
        </SelectComponent>
    );
}

export default Select;

export function SelectItem(props: ListBoxItemProps) {
    return (
        <ListBoxItem
            {...props}
            className={({ isFocused, isSelected }) =>
                `item ${isFocused ? "focused" : ""} ${
                    isSelected ? "selected" : ""
                } list-box-item ${css.SelectBoxItem}`
            }
        />
    );
}
