import css from "./InteractiveQuestionEditor.module.scss";
import {
    type BuildingBlock,
    createNewBlock,
} from "@/components/organisms/BuildingBlockMapper";
import { BuildingBlockMapperComponent } from "@/components/organisms/BuildingBlockMapper/BuildingBlockMapper.component";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    type SelectChangeEvent,
    Stack,
} from "@mui/material";
import type { ValidationError } from "yup";
import { useTranslation } from "react-i18next";
import {
    BuildingBlockType,
    type InteractiveQuestionInput,
} from "@/components/organisms/BuildingBlockMapper/BuildingBlocks.types";
import { useEffect } from "react";

export interface InteractiveQuestionEditorProps {
    question: InteractiveQuestionInput;
    onChange: (question: BuildingBlock) => void;
    errors?: ValidationError[];
}

export const InteractiveQuestionEditor = ({
    question,
    onChange,
    errors,
}: InteractiveQuestionEditorProps) => {
    const { t } = useTranslation("common", {
        keyPrefix: "interactive-question-editor",
    });

    const handleNewType = (e: SelectChangeEvent) => {
        const blockType = e.target.value as BuildingBlockType;

        if (!Object.values(BuildingBlockType).includes(blockType)) {
            throw new Error(`Invalid block type: ${blockType}`);
        }

        onChange(createNewBlock(blockType));
    };

    const handleAnswerChanged = (question: BuildingBlock) => {
        onChange(question);
    };

    const interactiveErrors = errors?.map((error) => {
        return {
            ...error,
            path: error?.path?.split(".").at(-1),
        };
    });

    useEffect(() => {
        if (question) return;

        onChange(createNewBlock(BuildingBlockType.MPC));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={css.InteractiveQuestionEditor}>
            <FormControl sx={{ minWidth: 300, marginBottom: 2 }}>
                <Stack>
                    <InputLabel id="question">
                        {t("question-type", "Vraag type")}
                    </InputLabel>
                    <Select
                        labelId="question"
                        id="question"
                        label={t("question-type", "Vraag type")}
                        value={question?.type ?? BuildingBlockType.MPC}
                        onChange={handleNewType}
                    >
                        <MenuItem value={BuildingBlockType.MPC} selected={true}>
                            {t("mpc", "Meerkeuze vraag")}
                        </MenuItem>
                        <MenuItem value={BuildingBlockType.MPC_WITH_IMAGES}>
                            {t("mpc", "Meerkeuze vraag met afbeeldingen")}
                        </MenuItem>
                        <MenuItem value={BuildingBlockType.STAND}>
                            {t("stand", "Stelling vraag")}
                        </MenuItem>
                    </Select>
                </Stack>
            </FormControl>

            <BuildingBlockMapperComponent
                {...question}
                errors={interactiveErrors}
                elevate={false}
                onChange={handleAnswerChanged}
            />
        </div>
    );
};

export default InteractiveQuestionEditor;
