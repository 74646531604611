import type { MouseEvent } from "react";
import type { ValidationError } from "yup";
import { type MediaToolbarAction } from "@/components/atoms/MediaToolbar";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Image, Videocam } from "@mui/icons-material";
import css from "./AssetUpload.module.css";
import { ExternalVideoAssetUpload } from "./ExternalVideoAssetUpload.component";
import { ImageAssetUpload } from "./ImageAssetUpload.component";
import type { AssetDto } from "../../../../../packages/apollo";
import ValidationErrorLabel from "@/components/atoms/ValidationErrorLabel";
import { useDetailedAsset } from "@bespeak/ui/src/hooks";
import { useModeWithDefault } from "@/components/organisms/AssetUpload/useModeWithDefault";

export type AssetUploadMode = "file" | "external_video";

export type AssetUploadProps = {
    availableModes: AssetUploadMode[];
    asset?: AssetDto;
    onChange: (asset: AssetDto | null) => void;
    label?: string;
    error?: ValidationError;
    assetActions?: MediaToolbarAction[];
    loop?: boolean;
    autoplay?: boolean;
    showExternalVideoSettings?: boolean;
};

export function AssetUpload(props: AssetUploadProps) {
    const { availableModes, asset, error } = props;
    const detailedAsset = useDetailedAsset(asset);
    const [mode, setMode] = useModeWithDefault(availableModes, detailedAsset);

    const handleSwitchMode = (
        _event: MouseEvent<HTMLElement>,
        newMode: AssetUploadMode | null,
    ) => {
        if (newMode === null) return;
        setMode(newMode ?? undefined);
    };

    return (
        <div className={css.AssetUpload}>
            <ValidationErrorLabel keyPrefix={"media"} error={error} />

            {availableModes.length > 1 &&
                !asset?.id && ( // Only if no asset is present should the user be able to switch modes
                    <ToggleButtonGroup
                        size={"small"}
                        exclusive
                        value={mode}
                        onChange={handleSwitchMode}
                    >
                        {availableModes.includes("file") && (
                            <ToggleButton value="file" title={"Afbeelding"}>
                                <Image />
                            </ToggleButton>
                        )}
                        {availableModes.includes("external_video") && (
                            <ToggleButton
                                value="external_video"
                                title={"Video"}
                            >
                                <Videocam />
                            </ToggleButton>
                        )}
                    </ToggleButtonGroup>
                )}

            {mode === "file" && (
                <div className={css.UploadContainer}>
                    <ImageAssetUpload {...props} asset={detailedAsset} />
                </div>
            )}

            {mode === "external_video" && (
                <div className={css.UploadContainer}>
                    <ExternalVideoAssetUpload
                        {...props}
                        asset={detailedAsset}
                    />
                </div>
            )}
        </div>
    );
}

export default AssetUpload;
