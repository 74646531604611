import clsx from "clsx";
import css from "./SearchField.module.css";
import { SearchIcon, CloseIcon } from "@bespeak/ui";
import { useTranslation } from "react-i18next";
import { SearchBox } from "react-instantsearch";
import { useInstantSearch } from "react-instantsearch";
import { NavigateToSearchResult } from "@/lib/navigateToSearchResult/navigateToSearchResult";
import { useNavigate } from "react-router-dom";
import { AlgoliaSearchContext } from "@/provider/AlgoliaSearch";
import type { AlgoliaSearchContextType } from "@/provider/AlgoliaSearch/AlgoliaSearch.types";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const SearchField = () => {
    const { t } = useTranslation("common", {
        keyPrefix: "search",
    });
    const [error, setError] = useState<string | null>(null);
    const { searchActive, handleDisableSearchActive } =
        useContext<AlgoliaSearchContextType>(AlgoliaSearchContext);
    const navigate = useNavigate();
    const { results } = useInstantSearch();
    const location = useLocation();
    const previousLocation = useRef(location);

    const { didacticToolId } = useParams<{
        didacticToolId: string;
    }>();

    useEffect(() => {
        if (previousLocation.current !== location) {
            handleDisableSearchActive();
            previousLocation.current = location;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (!searchActive) {
            setError(null);
        }
    }, [searchActive]);

    const handleNavigate = () => {
        const searchResult = NavigateToSearchResult(results.hits?.at(0));

        if (!searchResult) {
            setError(
                t(
                    "errors.noResults",
                    "Helaas, we konden niks vinden met deze snelcode",
                ),
            );
        }

        if (searchResult) {
            setError(null);
            navigate(searchResult);
            setTimeout(() => {
                handleDisableSearchActive();
            }, 200);
        }
    };

    if (!searchActive || didacticToolId) {
        return null;
    }

    return (
        <div
            className={clsx(css.searchWrapper, {
                [css.searchWrapperActive]: searchActive,
            })}
        >
            <div className={css.searchFieldInputWrapper}>
                <div className={css.searchFieldInput}>
                    <SearchBox
                        autoFocus={true}
                        resetIconComponent={CloseIcon}
                        submitIconComponent={SearchIcon}
                        className={css.searchFieldInputField}
                        placeholder={t("search", "Voer snelcode in")}
                        onSubmit={handleNavigate}
                        classNames={{
                            root: css.searchFieldInputRoot,
                            form: css.searchFieldInputForm,
                            reset: css.searchFieldInputReset,
                            submit: css.searchFieldInputSubmit,
                            input: css.searchFieldInputInput,
                            loadingIndicator:
                                css.searchFieldInputLoadingIndicator,
                        }}
                    />
                </div>
            </div>
            {error ? (
                <div className={css.searchFieldResults}>
                    <div className={css.searchFieldError}>{error}</div>
                </div>
            ) : null}
        </div>
    );
};
