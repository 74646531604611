import ReferenceCard from "@bespeak/ui/src/ReferenceCard/ReferenceCard.component";
import { Asset } from "@bespeak/ui";
import { useDetailedAsset } from "@bespeak/ui/src/hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "@bespeak/ui";
import ConvertedHTML from "@bespeak/ui/src/ConvertedHTML/ConvertedHTML";
import clsx from "clsx";
import css from "./ReferenceCardGridItem.module.scss";
import Add from "@mui/icons-material/Add";

type ReferenceCardGridItemProps = {
    item: {
        assetId: string;
        lead?: string;
        title?: string;
        subtitle?: string;
        description?: string;
        id: string;
        isBlock: boolean;
    };
};

export const ReferenceCardGridItem = ({
    item,
    isBlock = false,
    onAddItem,
    isAdded = false,
}: {
    item: ReferenceCardGridItemProps["item"];
    isBlock?: boolean;
    onAddItem: (item: any) => void;
    isAdded?: boolean;
}) => {
    const { t } = useTranslation("common", {
        keyPrefix: "list-reference-cards",
    });
    const detailedAsset = useDetailedAsset(item?.assetId);
    const navigate = useNavigate();
    const onClickItem = (id: string) => {
        navigate(`/content/reference-cards/${id}`);
    };

    const handleClick = () => {
        onAddItem(item);
    };

    return (
        <ReferenceCard
            className={clsx("ReferenceCardCms", css.ReferenceCardCms, {
                [css.Added]: isAdded,
            })}
        >
            {item.assetId && detailedAsset && (
                <ReferenceCard.Media>
                    <Asset asset={detailedAsset || null} />
                </ReferenceCard.Media>
            )}

            <ReferenceCard.Header>
                {item.lead && (
                    <ReferenceCard.Lead>{item?.lead}</ReferenceCard.Lead>
                )}
                {item.title && (
                    <ReferenceCard.Title>{item?.title}</ReferenceCard.Title>
                )}
                {item.subtitle && (
                    <ReferenceCard.Subtitle>
                        {item?.subtitle}
                    </ReferenceCard.Subtitle>
                )}
            </ReferenceCard.Header>
            <ReferenceCard.Main>
                <ConvertedHTML value={item?.description} />
            </ReferenceCard.Main>
            <ReferenceCard.Footer>
                {!isBlock && (
                    <Button onClick={() => onClickItem(item.id)} variant="text">
                        {t("edit")}
                    </Button>
                )}

                {isBlock && (
                    <Button onClick={handleClick} variant="text">
                        <Add />
                    </Button>
                )}
            </ReferenceCard.Footer>
        </ReferenceCard>
    );
};

export default ReferenceCardGridItem;
