import css from "./DialogBody.module.css";

export type DialogBodyProps = {
    children: React.ReactNode;
};

export const DialogBody = ({ children }: DialogBodyProps) => {
    return <div className={css.DialogBody}>{children}</div>;
};

export default DialogBody;
